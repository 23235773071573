import React, { useEffect } from "react";
import { Row, Col } from "antd";

import logo from "assets/logo.svg";

const background = {
  background:
    'linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("/img/pictures/contacts.jpg")',
  backgroundSize: "cover",
  backgroundPosition: "left",
};

const logoImg = {
  width: "192px",
};

const rowBox = {
  //backgroundColor: "#080808",
  backgroundColor: "white",
  color: "black",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const box = {
  padding: "min(15vw, 80px)",
  fontSize: "1.2em",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

function Contacts() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="slide slide-first" style={background}>
        <div>
          <div className="container">
            <Row className="vertical-center rouded-corners" style={rowBox}>
              <Col className="vertical-center box justified" style={box}>
                <img src={logo} alt="logo" style={logoImg} />
              </Col>
              <Col
                className="vertical-center box right-space justified"
                style={box}
              >
                <div>
                  <h1 style={{ color: "black" }}>Get In Touch</h1>
                  <p>
                    E-mail:{" "}
                    <a href="mailto:info@weirdframes.com">
                      info@weirdframes.com
                    </a>
                  </p>
                  <p>
                    Facebook:{" "}
                    <a href="https://www.facebook.com/weirdframes">
                      @WeirdFrames
                    </a>
                  </p>
                  <p>
                    Instagram:{" "}
                    <a href="https://www.instagram.com/weirdframesofficial/">
                      @weirdframesofficial
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contacts;
