import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import Confetti from "react-dom-confetti";

import { YoutubeOutlined } from "@ant-design/icons";

import { Separator, VideoSlide, TwoSidedBlock } from "components/common";

const config = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: 70,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "500px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

const jumbotronStyle = {
  textAlign: "center",
  textTransform: "uppercase",
  fontSize: "min(12vw, 72px)",
  fontWeight: 750,
};

const Section = ({ id, title, children }) => {
  return (
    <div id={id}>
      <h5 style={{ textTransform: "uppercase" }}>{title}</h5>
      <Separator height={0.5} />
      <div style={{ textAlign: "justify" }}>{children}</div>
      <Separator height={2} />
    </div>
  );
};

function Project() {
  const [burst, setBurst] = useState(false);
  const [cooldown, setCooldown] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function activateConfetti() {
    if (cooldown === false) {
      setBurst(true);
      setCooldown(true);

      resetConfetti();
    }
  }

  function resetConfetti() {
    setTimeout(function () {
      setBurst(false);
      setCooldown(false);
    }, 3000);
  }

  return (
    <>
      <VideoSlide src="mJ8gRxjFbWY" youtube first>
        <Button
          type="primary"
          shape="round"
          href="https://youtu.be/mJ8gRxjFbWY"
          size="large"
        >
          <YoutubeOutlined /> WATCH VIDEO
        </Button>
      </VideoSlide>
      <div className="jumbotron">
        <div className="jumbotron-content">
          <div onMouseOver={activateConfetti}>
            <div style={{ marginLeft: "50%" }}>
              <Confetti active={burst} config={config} />
            </div>
            <h4>We are proud to receive the</h4>
            <h1 style={jumbotronStyle}>
              Metahuman special{" "}
              <a href="https://www.reallusion.com/iclone/event/2021-lip-sync-animation-contest/winner.html">
                award
              </a>
              !
            </h1>
          </div>
        </div>
      </div>
      <div className="slide">
        <div className="container">
          <Section title="About the project" id="description">
            <p>
              This 30-seconds video was created as our entry to the "2021 iClone
              Lip Sync Animation Contest" hosted by Reallusion. It was inspired
              from our ongoing project codenamed{" "}
              <Link to="/projects/niflheim">"Project Nifleheim"</Link>.
              <br />
              It focuses on lonesome warrior facing a deadly threat. He sings a
              battle cry to summon his strength. Created in Unreal Engine with
              MetaHumans and and Iclone.
            </p>

            <Separator height={2} />

            <TwoSidedBlock
              title="The magic of Metahumans"
              description="Metahumans are incredibly realistic digital humans, 
                providing a high-quality base for any character.
                The ability to control single features, and to blend multiple physiognomies
                allows the creation of unique faces, enhanced by high quality textures."
              imageSrc="/img/pictures/reallusion2021/metahuman_creator.png"
              alignment="left"
            />

            <Separator height={2} />

            <TwoSidedBlock
              title="From Metahumans to characters"
              description="Although MetaHumans are extremely high-quality, 
                they benefit from ad hoc customization. Making a MetaHuman a unique chatacter
                can be achieved by further sculpting the base mesh, adding details as well as 
                painting custom textures to create visually stunning characters."
              imageSrc="/img/pictures/reallusion2021/substance_painter.png"
              alignment="right"
            />

            <Separator height={2} />

            <TwoSidedBlock
              title="Virtual environment in Unreal"
              description="The entire environment was built and rendered in Unreal Engine.
                Using ray-tracing and volumetric effects (e.g.: ground fog) we were able to achieve 
                the dark and misterious look we imagined for our scene. We were able to achieve the right aesthetics,
                playing with direct and indirect illumination to keep the character partially visible and 
                lighten by a trembling fire."
              imageSrc="/img/pictures/reallusion2021/ue_world.png"
              alignment="left"
            />

            <Separator height={2} />

            <TwoSidedBlock
              title="Lip-Sync and animation in iClone"
              description="The star of this project is, indeed, iClone.
                This software has a consolidated role in our work pipeline, 
                serving as the starting point to create most of the animation for our projects.
                In particular we leveraged the AccuLips and IPhone mocap tools to record and finetune
                the facial animations and lip-syncing."
              imageSrc="/img/pictures/reallusion2021/iclone_lipsync.png"
              alignment="right"
            />
          </Section>
        </div>
      </div>
    </>
  );
}

export default Project;
