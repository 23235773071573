import React, { useEffect, useState } from "react";
import { Button, Carousel, Tag } from "antd";

import { DownOutlined, UpOutlined } from "@ant-design/icons";

import { Separator, VideoSlide, TwoSidedBlock } from "components/common";

const Section = ({ id, title, children }) => {
  return (
    <div id={id}>
      <h5 style={{ textTransform: "uppercase" }}>{title}</h5>
      <Separator height={0.5} />
      <div style={{ textAlign: "justify" }}>{children}</div>
      <Separator height={2} />
    </div>
  );
};

const CarouselImg = ({ src }) => {
  return (
    <div>
      <img
        src={src}
        alt={src.replace(".jpg", "")}
        style={{ maxWidth: "100%" }}
      />
    </div>
  );
};

const ReadMoreBlock = ({ children }) => {
  const [showText, setShowText] = useState(false);
  const [button, setButtonText] = useState({
    text: "More",
    icon: <DownOutlined />,
  });

  function revealText() {
    const show = !showText;
    setShowText(show);
    if (show === true) setButtonText({ text: "Less", icon: <UpOutlined /> });
    else setButtonText({ text: "More", icon: <DownOutlined /> });
  }

  return (
    <>
      {showText === true && <div>{children}</div>}
      <Button type="link" style={{ padding: 0 }} onClick={() => revealText()}>
        {"Read " + button.text} {button.icon}
      </Button>
    </>
  );
};

function Project() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <VideoSlide src="/video/project_niflheim.mp4" first>
        <img
          src="/img/logos/niflheim_logo.png"
          alt="logo"
          style={{ maxWidth: "min(80vw, 400px)" }}
        />
      </VideoSlide>
      <div className="slide">
        <div className="container">
          <Section title="About the project" id="description">
            <p>
              We are proud to introduce you to our latest effort, codenamed
              Project Niflheim. It is meant to be a single-player, story driven,
              third person adventure, starring fierce yet deeply
              conflicted human Viking warriors, set in a harsh, brutal and
              stunning world.
            </p>
            <ReadMoreBlock>
              <p>
                On our take on Norse legends and history, we want you, the
                player, to step into the “wolf-skin” of a berserker, Arvo, and
                witness his strength, his fortitude, but also his shadows, his
                bestiality. He will face countless enemies, along with the
                strongest one: himself.
              </p>

              <p>
                We think videogames are the next natural evolution in our
                mission to tell stories, thanks to their ability to
                extend the experience of storytelling by letting the user become
                pivotal part in the development of the events. The game, the
                story, the actions unfold as the player immerse itself into a
                world skillfully crafted and master its own playstyle thanks to
                a dynamic combat system. We want to make the players feel
                empowered as they step fully armed into an unknown land where
                nothing is what it seems, and a simple discovery turns into a
                war for survival. To quote a famous character: Arvo is the best
                at what he does, but what he does is not very nice. He has the
                tools and the experience to evening the odds against the most
                dangerous foes, but doing so without proper control could turn
                him into that beast everyone around him keeps telling him he is.
                It’s something the players will have to consider along their
                journey.
              </p>
            </ReadMoreBlock>

            <Separator height={2} />

            <TwoSidedBlock
              title="Story driven"
              description="A foreign and mysterious island. A raid gone wrong. 
                A formidable force hiding beneath a sea of mist. 
                Alone, broken, furious, you are Úlfheðinn, a ‘warrior in wolf-skin’. 
                You are Arvo, a Norseman who will have to survive the Island’s dangers – and his own demons. "
              imageSrc="/img/pictures/niflheim/niflheim_story.gif"
              alignment="right"
            />

            <Separator height={2} />

            <TwoSidedBlock
              title="Cinematic look"
              description="Explore the Island of Mist and witness its violence and beauty. 
                Powered by Unreal Engine, real time ray-tracing and latest technologies in the market,
                Project Niflheim brings you the most stunning visuals and cinematic look."
              imageSrc="/img/pictures/niflheim/niflheim_cinematic.gif"
              alignment="left"
            />

            <Separator height={2} />

            <TwoSidedBlock
              title="Dynamic gameplay"
              description="Experience the full power of a berserker 
                and control the battlefield through fear, agility, and brutality. 
                Fight in a trance and descend into complete bestiality, but beware, 
                Óðinn asks a steep price for the strength he grants…"
              imageSrc="/img/pictures/niflheim/niflheim_gameplay.gif"
              alignment="right"
            />
          </Section>

          <Separator height={2} />

          <Section title="Gallery" id="gallery">
            <Carousel autoplay>
              <CarouselImg src="/img/pictures/niflheim/niflheim_blood.jpg" />
              <CarouselImg src="/img/pictures/niflheim/niflheim_ocean.jpg" />
              <CarouselImg src="/img/pictures/niflheim/niflheim_forest.jpg" />
              <CarouselImg src="/img/pictures/niflheim/niflheim_cliff.jpg" />
              <CarouselImg src="/img/pictures/niflheim/niflheim_glacier.jpg" />
              <CarouselImg src="/img/pictures/niflheim/niflheim_headshot.jpg" />
              <CarouselImg src="/img/pictures/niflheim/niflheim_mist.jpg" />
              <CarouselImg src="/img/pictures/niflheim/niflheim_path.jpg" />
              <CarouselImg src="/img/pictures/niflheim/niflheim_roar.jpg" />
            </Carousel>
          </Section>

          <Separator height={2} />

          <Section title="Project Roadmap" id="timeline">
            <p>
              We value transparency and we want you to always be updated on our
              work, that's why we created a pubic Trello board. It will always
              be alligned to our latest progress, completed milestones, to the
              general state of the project and to our next steps.
              <br />
              Each task comes with a set of tags, representing the cathegory
              (e.g.:{" "}
              <Tag color="#eb5a46">
                <b>gameplay</b>
              </Tag>
              ,{" "}
              <Tag color="#0079bf">
                <b>assets</b>
              </Tag>
              ) and the current state (e.g.:{" "}
              <Tag color="#ff9f1a">
                <b>todo</b>
              </Tag>
              ,{" "}
              <Tag color="#f1c000">
                <b>in progress</b>
              </Tag>
              ,{" "}
              <Tag color="#61bd4f">
                <b>done</b>
              </Tag>
              ). Check our current active tasks and what they mean for the
              advancement of the project.
            </p>
            <Separator height={1} />
            <iframe
              title="Trello Board"
              src="https://trello.com/b/j9z3XZbA.html"
              frameBorder="0"
              width="100%"
              height="600"
              className={"rouded-corners"}
            />
          </Section>
        </div>
      </div>
    </>
  );
}

export default Project;
