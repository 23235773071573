import React, { useEffect } from "react";
import { Carousel, Button, Row, Col } from "antd";

import { Separator, VideoSlide, TwoSidedBlock } from "components/common";

import { YoutubeOutlined } from "@ant-design/icons";

const headerBackground = {
  background: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/img/pictures/antares/antares_header.jpg")',
  backgroundSize: "cover",
  backgroundPosition: "center",
};

const Section = ({ id, title, children }) => {
  return (
    <div id={id}>
      <h5 style={{ textTransform: "uppercase" }}>{title}</h5>
      <Separator height={1} />
      <div style={{ textAlign: "justify" }}>{children}</div>
      <Separator height={2} />
    </div>
  );
};

const CarouselImg = ({ src }) => {
  return (
    <div>
      <img
        src={src}
        alt={src.replace(".jpg", "").replace(".png", "")}
        style={{ maxWidth: "100%" }}
      />
    </div>
  );
};

function Project() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="slide slide-first" style={headerBackground}>
        <div style={{ textAlign: "center" }}>
          <img
            src="/img/logos/antares_logo.png"
            alt="logo"
            style={{ maxWidth: "min(80vw, 400px)" }}
          />
        </div>
      </div>
      <div className="slide">
        <div className="container">
          <Section
            title="Welcome to Kaamos City!"
            id="description"
          >
            <Row gutter={32}>
              <Col lg={{ span: 12 }}>
                <p>
                  Welcome to Kaamos City, the most technological advanced place
                  in the world. Many would say it the most of a lot of things.
                  Most luxurious. Most wasteful. Most civilized. Most violent.
                  Most bright. Most cold. Most liberal. Most unequal.
                </p>
                <Separator height={1} />
                <p>
                  Dive deep into the world of <b>Project Antares</b>, a futuristic
                  dystopian city inspired by the cyberpunk genre. Antares themes
                  are grounded in topical issues, such as Artificial Intelligence (AI),
                  technology and society, consequences of population overgrown and
                  the excesses of a flawed economy system.
                </p>
                <Separator height={1} />
                <p>
                  As always, with project Antares, we try to conjugate our passion
                  for storytelling with the latest technologies, pushing ourselves
                  to deliver the best quality.
                  <br />
                  To visualize the world of Antares, we rely on generative AI,
                  which has proven to be a mature technology.
                  Reflecting the themes of Antares, all of the concept arts, as 
                  well the soundtrack and some assets are creted by generative AIs, 
                  highlighting the gap reduction between humans and machines.
                </p>
                <Separator height={1} />
                <p>
                  Embark with us in this journey, and travel to the world of project Antares.
                </p>
              </Col>
              <Col lg={{ span: 12 }}>
                <img
                  src="/img/pictures/antares/antares_wallpaper.jpg"
                  alt="logo"
                  style={{ width: "100%" }}
                  className={"rouded-corners"}
                />
              </Col>
            </Row>
          </Section>
          <Separator height={2} />
        </div>
      </div>
    </>
  );
}

export default Project;
