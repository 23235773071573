import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Menu } from "antd";
import {
  YoutubeOutlined,
  InstagramOutlined,
  FacebookOutlined,
} from "@ant-design/icons";

const RightMenu = ({ mode, pathname, onSelectCallback }) => (
  <Menu mode={mode} selectedKeys={[pathname]} onSelect={onSelectCallback}>
    <Menu.Item key="about">
      <Link to="/about">About Us</Link>
    </Menu.Item>
    <Menu.Item key="projects">
      <Link to="/projects">Projects</Link>
    </Menu.Item>
    <Menu.Item key="contacts">
      <Link to="/contacts">Contacts</Link>
    </Menu.Item>

    <Menu.Item key="youtube">
      <a href="https://www.youtube.com/channel/UCIeSI05gN6-JnlLC72yo_yA">
        <YoutubeOutlined />
      </a>
    </Menu.Item>
    <Menu.Item key="instagram">
      <a href="https://www.instagram.com/weirdframesofficial/">
        <InstagramOutlined />
      </a>
    </Menu.Item>
    <Menu.Item key="facebook">
      <a href="https://www.facebook.com/weirdframes/">
        <FacebookOutlined />
      </a>
    </Menu.Item>
    {/*<Menu.Item key="twitter">
			<TwitterOutlined />
		</Menu.Item>*/}
  </Menu>
);

RightMenu.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default RightMenu;
