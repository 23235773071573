import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";

import logo from "assets/logo_h_light.svg";

const { Footer } = Layout;

class AppFooter extends Component {
  render() {
    return (
      <Footer>
        <img src={logo} alt="logo" className="logo" />

        <div className="block">
          <Link to="contacts">Contacts</Link>
          <Link to="linktree">Linktree</Link>
        </div>

        <p style={{fontSize: "14px"}}>
          Copyright © {new Date().getFullYear()} Weird Frames. All rights
          reserved.
        </p>
      </Footer>
    );
  }
}

export default AppFooter;
