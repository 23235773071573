import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AppLayout from "layouts/AppLayout";

// main pages
import LinkTree from 'pages/LinkTree';
import Home from 'pages/Home';
import About from 'pages/About';
import Contacts from 'pages/Contacts';
import Projects from 'pages/Projects';

// content pages
import VikingGame from 'pages/Projects/VikingGame';
import Horny from 'pages/Projects/Horny';
import Antares from 'pages/Projects/Antares';
import Reallusion2021 from 'pages/Projects/ContestReallusion';

// error pages
import NotFoundPage from 'pages/NotFoundPage';

import reportWebVitals from './reportWebVitals';

// style
import "index.less"

const AppRoute = ({
  component: Component,
  layout: Layout,
  pageTitle: Title,
  background: Background,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <AppRoute exact path="/" layout={AppLayout} component={Home} />
      <AppRoute exact path="/about" layout={AppLayout} component={About} />
      <AppRoute exact path="/contacts" layout={AppLayout} component={Contacts} />
      <AppRoute exact path="/projects" layout={AppLayout} component={Projects} />
      
      <AppRoute exact path="/projects/niflheim" layout={AppLayout} component={VikingGame} />
      <AppRoute exact path="/projects/horny" layout={AppLayout} component={Horny} />
      <AppRoute exact path="/projects/antares" layout={AppLayout} component={Antares} />
      <AppRoute exact path="/projects/reallusion2021" layout={AppLayout} component={Reallusion2021} />

      <AppRoute exact path="/linktree" layout={AppLayout} component={LinkTree} />

      <AppRoute exact path="/404" layout={AppLayout} component={NotFoundPage} />
      <Redirect to="/404" />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
