import React, { useEffect, useState } from "react";
import { Card, Carousel, Col, Row } from "antd";
import * as Realm from "realm-web";

import {
  LinkOutlined
} from "@ant-design/icons";

import { DeviceSizeView, Separator, VideoSlide } from "components/common";

const { Meta } = Card;

const NewsLink = ({ news, gutter }) => {
  return (
    news.content.link ? <div style={{ textAlign: "right" }}>
      <a href={news.content.link}><LinkOutlined /></a>
    </div> : <></>
  );
};

const NewsMainCard = ({ news, gutter }) => {
  return (
    <Card
      hoverable
      cover={<img alt="news-cover" src={"/img/covers/" + news.content.cover} />}
      className="news-card-main"
      style={{ marginBottom: "24px" }}
    >
      <div className="news-card-body">
        <Meta
          title={<h5 style={{ whiteSpace: "initial" }}>{news.title}</h5>}
          description={news.content.text}
        />

        <NewsLink news={news} />
      </div>
    </Card>
  );
};

const NewsCard = ({ news }) => {
  return (
    <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
      <Card
        hoverable
        cover={
          <img alt="news-cover" src={"/img/covers/" + news.content.cover} />
        }
        className="news-card"
      //style={{ height: "100%" }}
      >
        <div className="news-card-body">
          <Meta
            title={<h5 style={{ whiteSpace: "initial" }}>{news.title}</h5>}
            description={news.content.text}
          />
          <NewsLink news={news} />
        </div>
      </Card>
    </Col>
  );
};

const Home = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const app = new Realm.App({ id: "web-app-myllw" });
    const credentials = Realm.Credentials.apiKey(
      "9NCYWjxeuT26kYKHdB8zEzDEAr6z6nBw2BBplVdbWYnpibHip7zMgupLh2M68wuf"
    );

    app.logIn(credentials).then((user) => {
      user.functions.getAllNews().then((allNews) => {
        //console.log(news);
        setNews(allNews);
      });
    });
  }, []);

  return (
    <>
      <VideoSlide src="/video/show_reel.mp4" first>
        <img
          src="/img/logos/weird_frames.png"
          alt="logo"
          style={{ maxWidth: "min(80vw, 800px)" }}
        />
      </VideoSlide>

      {/*<div className="jumbotron">
        <div className="jumbotron-content">
          <h1 style={{fontSize: "72px", fontWeight: 750, margin:0 }}>
            We create stories
          </h1>
          <h1 style={{fontSize: "72px", fontWeight: 750, margin:0 }}>
            You live them
          </h1>
        </div>
      </div>*/}

      <div className="slide">
        <div className="container">
          <div>
            <h2 style={{ textTransform: "uppercase" }}>What's New</h2>
            <Separator height={2} />
            <div>
              {Object.keys(news).length > 0 && (
                <>
                  <DeviceSizeView screen_size="lg" mode="gt">
                    <NewsMainCard news={news[0]} gutter={24} />
                    <Row gutter={[24, 24]}>
                      <NewsCard news={news[1]} />
                      <NewsCard news={news[2]} />
                      <NewsCard news={news[3]} />
                    </Row>
                  </DeviceSizeView>

                  <DeviceSizeView screen_size="lg" mode="lt">
                    <Carousel id="news-carousel" autoplay dots={false}>
                      {Object.keys(news).length > 0 &&
                        news.map((el, idx) => {
                          return <NewsCard key={idx} news={el} />;
                        })}
                    </Carousel>
                  </DeviceSizeView>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
