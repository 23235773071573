import Navbar from "components/Navbar";
import Footer from "components/Footer";
import "./style.less";

import grain from "assets/noise.svg";

const AppLayout = ({ children }) => {
  return (
    <>
      <Navbar />
      {/* <div className="cgrain" style={{ backgroundImage: `url(${grain})` }} /> */}
      <div className="App">{children}</div>
      <Footer />
    </>
  );
};

export default AppLayout;
