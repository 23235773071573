import React, { useEffect, useState } from "react";

const videoContainer = {
  position: "absolute",
  width: "100%",
  height: "100vh",
  overflow: "hidden",
  background: "black",
};

const player = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const overVideoContent = {
  zIndex: 9,
  textAlign: "center",
};

const VideoSlide = ({ src, children, youtube = false, first = false }) => {
  const [width, setWidth] = useState(window.outerWidth);
  const [height, setHeight] = useState(window.outerHeight);

  useEffect(() => {
    const handleResizeWindow = () => {
      setWidth(window.outerWidth);
      setHeight(window.outerHeight);
    };
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  let playerStyle = {};

  playerStyle["height"] = height + "px";
  playerStyle["width"] = width + "px";

  //let currWidth = (height / 9) * 16;
  if (window.innerWidth < window.innerHeight) {
    let pWidth = Math.ceil((height / 9) * 16);
    pWidth = Math.max(pWidth, width);
    let pHeight = Math.ceil((pWidth / 16) * 9);
    playerStyle["height"] = pHeight + "px";
    playerStyle["width"] = pWidth + "px";
  } else {
    let pHeight = Math.ceil((width / 16) * 9);
    pHeight = Math.max(pHeight, height);
    let pWidth = Math.ceil((pHeight / 9) * 16);
    playerStyle["height"] = pHeight + "px";
    playerStyle["width"] = pWidth + "px";
  }

  const heightFirst = { height: first ? "calc(100vh - 90px)" : "100vh" };

  const ytSrc =
    "https://www.youtube.com/embed/" +
    src +
    "?autoplay=1&showinfo=0&controls=0&autohide=1&mute=1&loop=1&playlist=" +
    src +
    "&modestbranding=1&showinfo=0&enablejsapi=1&playsinline=1&widgetid=3&rel=0";

  function onPlayerReady(){
    console.log("player ready");
  }

  return (
    <>
      <div style={{ ...videoContainer, ...heightFirst }}>
        {youtube ? (
          <iframe
            id="ytplayer"
            width={playerStyle["width"]}
            height={playerStyle["height"]}
            style={{ ...player }}
            src={ytSrc}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            onReady={onPlayerReady}
          />
        ) : (
          <video autoPlay muted loop style={{ ...playerStyle, ...player }}>
            <source src={src} type="video/mp4" />
          </video>
        )}
      </div>
      <div className="slide slide-first">
        <div style={overVideoContent}>{children}</div>
      </div>
    </>
  );
};

export default VideoSlide;
