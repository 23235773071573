import React from "react";
import { Row, Col } from "antd";

const TwoSidedBlock = ({
  title,
  description,
  children,
  imageSrc,
  alignment = "left",
  use_gradient = true,
  full_background = false,
  background_align = "center",
  gutter = 0,
  rate = 0.5,
}) => {
  const error = '`alignment` must be "left" or "right"!';

  if (!["left", "right"].includes(alignment)) throw error;

  let blockOrder = alignment === "right" ? [0, 1] : [1, 0];

  const imageGradient = use_gradient
    ? {
      maskImage:
        "linear-gradient(to " +
        alignment +
        ", rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 60%)",
      WebkitMaskImage:
        "linear-gradient(to " +
        alignment +
        ", rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 60%)",
    }
    : {};
  
  const img = "linear-gradient(to " + alignment + ", rgba(0,0,0,1) 15% , rgba(0,0,0,0.95) 20% , rgba(0,0,0,0.70) 40%, rgba(0,0,0,0.1) 100%),url(" + imageSrc + ")";

  const rowBackground = full_background ? {
    backgroundImage: img,
    backgroundSize: "cover",
    backgroundPosition: background_align,
  } : {};

  rate = Math.min(Math.max(rate, 0), 1);
  const col_0_size = Math.round(24 * rate);
  const col_1_size = Math.round(24 * (1 - rate));
  return (
      <Row gutter={gutter} style={{ ...rowBackground }}>
        <Col
          md={{ order: 0 }}
          lg={{ span: col_0_size, order: blockOrder[0] }}
        >
          <div style={{ height: "100%" }}>
            {title && <h3 style={{ textTransform: "uppercase" }}>{title}</h3>}
            {description && <p>{description}</p>}
            {children}
          </div>
        </Col>
        <Col md={{ order: 1 }} lg={{ span: col_1_size, order: blockOrder[1] }}>
          {full_background ? <></> :
            <img
              src={imageSrc}
              alt="logo"
              style={{ maxWidth: "100%", ...imageGradient }}
              className={"rouded-corners"}
            />}
        </Col>
      </Row>
  );
};

export default TwoSidedBlock;
