import React, { Component } from 'react';
import { Link } from "react-router-dom";
import NavbarMenu from './NavbarMenu'
import { Layout, Drawer, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import DeviceSizeView from 'components/common/DeviceSizeView';

import logo from 'assets/logo_h_light.svg';

const { Header } = Layout;

class Navbar extends Component {
    state = {
        current: 'home',
        visible: false,
    }
    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    onSelectCallback = (item) => {
        this.setState({current: item.key});
    }

    render() {
        return (
            <Header>
                <nav className="navbar">
                    <div className="navbar-logo">
                        <Link to='/' onClick={this.onSelectCallback}>
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>

                    <DeviceSizeView threshold={767}>
                        <div className="navbar-menu">
                            <NavbarMenu mode="horizontal" pathname={this.state.current} onSelectCallback={this.onSelectCallback}/>
                        </div>
                    </DeviceSizeView>

                    <Button className="navbar-toggler" onClick={this.showDrawer} type="link" icon={<MenuOutlined />} />

                    <Drawer
                        placement="right"
                        closable={false}
                        onClose={this.onClose}
                        visible={this.state.visible}
                        onClick={this.onClose}
                    >
                        <NavbarMenu mode="inline" />
                    </Drawer>
                </nav>
            </Header>
        );
    }
}

export default Navbar;