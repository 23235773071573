import { Button } from "antd";

import {
    GlobalOutlined,
    YoutubeOutlined,
    InstagramOutlined,
    FacebookOutlined,
    TwitterOutlined,
    MediumOutlined
} from "@ant-design/icons";

import { Separator } from "components/common";
//import logo from "assets/logo.svg";

const bigText = {
    fontSize: "1.5em",
    textAlign: "center",
};

const button = {
    width: '18rem',
    minHeight: '3.5rem',
    fontSize: "1.2rem",
    display: "flex",
    padding: "0",
}

const buttonContent = {
    margin: "auto",
}

function LinkTree() {
    return (
        <>
            <div className="slide slide-first" style={bigText}>
                <div>
                    <Button href="https://www.weirdframes.com" size="large" style={button}><div style={buttonContent}> <GlobalOutlined /> WeirdFrames.com</div></Button>
                    <Separator />
                    <Button href="https://www.youtube.com/channel/UCIeSI05gN6-JnlLC72yo_yA" size="large" style={button}><div style={buttonContent}> <YoutubeOutlined /> YouTube</div></Button>
                    <Separator />
                    <Button href="https://www.instagram.com/weirdframesofficial" size="large" style={button}><div style={buttonContent}> <InstagramOutlined /> Instagram</div></Button>
                    <Separator />
                    <Button href="https://medium.com/@weirdframes" size="large" style={button}><div style={buttonContent}> <MediumOutlined /> Medium</div></Button>
                    <Separator />
                    <Button href="https://www.facebook.com/weirdframes" size="large" style={button}><div style={buttonContent}> <FacebookOutlined /> Facebook</div></Button>
                    <Separator />
                    <Button href="https://twitter.com/WeirdFrames" size="large" style={button}><div style={buttonContent}> <TwitterOutlined /> Twitter</div></Button>
                </div>
            </div>
        </>
    );
}

export default LinkTree;


// https://www.artstation.com/weirdframes