import React, { useEffect } from "react";
import { Carousel, Button, Row, Col } from "antd";

import { Separator, VideoSlide, TwoSidedBlock } from "components/common";

import { YoutubeOutlined } from "@ant-design/icons";

const Section = ({ id, title, children }) => {
  return (
    <div id={id}>
      <h5 style={{ textTransform: "uppercase" }}>{title}</h5>
      <Separator height={1} />
      <div style={{ textAlign: "justify" }}>{children}</div>
      <Separator height={2} />
    </div>
  );
};

const CarouselImg = ({ src }) => {
  return (
    <div>
      <img
        src={src}
        alt={src.replace(".jpg", "").replace(".png", "")}
        style={{ maxWidth: "100%" }}
      />
    </div>
  );
};

function Project() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <>
      <VideoSlide src="/video/project_horny.mp4" first>
        <div>
          <img
            src="/img/logos/horny_logo.png"
            alt="logo"
            style={{ maxWidth: "min(80vw, 400px)" }}
          />
          <br />
          <Button
            type="primary"
            shape="round"
            href="https://youtu.be/5vn53NC-zDI"
            size="large"
          >
            <YoutubeOutlined /> WATCH FULL SORTMOVIE
          </Button>
        </div>
      </VideoSlide>
      <div className="slide">
        <div className="container">
          <Section
            title="He’s not much of a demon, but he’s gonna put on one hell of a show…"
            id="description"
          >
            <Row gutter={32}>
              <Col lg={{ span: 12 }}>
                <p>
                  Meet Horny. You all, probably, know him as the proud face of
                  the Weird Frames Company, the <i>“Diabolical Dancer”</i>,{" "}
                  <i>“the One-Eyed Demon Without a Past”</i>. Turns out, he has
                  a past after all. A past with shadows and secrets, things that
                  he would rather see forgotten and buried. Today we’ll found
                  out all about them! Get acquainted with the Legend!
                </p>
                <Separator height={1} />
                <p>
                  This short video is meant to present our new brand icon, aka
                  Horny, in a playful and idiosyncratic way. Or in one word: in
                  a Weird way. This video, narrated as a pseudo-documentary, is
                  fully created in CGI thanks to Unreal and other cutting-edge
                  technologies.
                </p>
                <Separator height={1} />
                <p>
                  Here we are to tell you all that Weird Frames is back, hoping
                  to bring you both quality and laughs (most at ourselves, we
                  get it). It’s just the beginning of a journey…
                </p>
              </Col>
              <Col lg={{ span: 12 }}>
                <img
                  src="/img/pictures/horny/forny_wallpaper.jpg"
                  alt="logo"
                  style={{ width: "100%" }}
                  className={"rouded-corners"}
                />
              </Col>
            </Row>
          </Section>
          <Separator height={2} />
          <Section title="Genesis of the myth" id="genesis">
            <Row gutter={32}>
              <Col md={{ order: 1 }} lg={{ span: 12, order: 0 }}>
                <Carousel autoplay>
                  <CarouselImg src="/img/pictures/horny/horny_sketch_1.png" />
                  <CarouselImg src="/img/pictures/horny/horny_sketch_2.png" />
                  <CarouselImg src="/img/pictures/horny/horny_sketch_3.png" />
                </Carousel>
              </Col>
              <Col md={{ order: 0 }} lg={{ span: 12, order: 1 }}>
                <p>
                  There's no way of telling the story of Horny without talking
                  about Weird Frames.
                </p>
                <Separator height={1} />
                <p>
                  Weird Frames was founded in 2013 with one goal in mind: bring
                  to you good stories. Original stories. Weird stories!
                  <br />
                  After six long years of silence, in 2021, Weird Frames was
                  finally back with new great stories to tell. All new contents,
                  same old weirdness!
                  <br />
                  But after six years we no longer felt represented by our old
                  logo. We wanted something new. Something bold. Something
                  unique. This is where Horny comes in.
                </p>
                <Separator height={1} />
                <p>
                  For months we experimented with new iterations of the original
                  WF monogram (and with other concepts you don't wanna know).
                  But none of them was able to satisfy our lust for weirdness.
                </p>
                <Separator height={1} />
                <p>
                  After several extenuating months, when we were ready to throw
                  in the towel. We were struck by a shocking revelation. That
                  was when we <i>"discovered"</i> Horny and we immediately fell
                  in love with him.
                </p>
              </Col>
            </Row>
          </Section>
          <Separator height={2} />
          <Section title="Bringing Horny to the small screen" id="video">
            <TwoSidedBlock
              title="Making of a 3d devil"
              description="Every story has its protagonist. 
                Our is a chubby, funny, weird looking demon with an artistic soul.
                Making the 3D model for Horny right was an extremely challenging task.
                It had to look puffy and clumsy but at the same time proportionate and beliavable, 
                like he was ready to jump onto a dance floor."
              imageSrc="/img/pictures/horny/horny_3d.jpg"
              alignment="right"
            />

            <Separator height={2} />

            <TwoSidedBlock
              title="That CGI is so... Un-real"
              description="The entire shortmovie is made in CGI, without using any real footage.
                To buid and render the scenes we relied on Unreal Engine 5.
                With its great built-in tools and technologies, like Nanite and the path-tracing, we were 
                able to obtain results previously unachievable on low budget."
              imageSrc="/img/pictures/horny/horny_unreal.gif"
              alignment="left"
            />

            <Separator height={2} />

            <TwoSidedBlock
              title="Deep-Faking a Metahuman"
              description="Putting real people in a CGI scene is never easy. 
                At first we tought about green screen, but that would have limited our 
                'physical' presence in the scene. This was a great opportunity to try out 
                the cutting edge deep-fake technology. We used MetaHumans as base bodies 
                and replaced the faces with the AI."
              imageSrc="/img/pictures/horny/horny_deepfake.gif"
              alignment="right"
            />
          </Section>
        </div>
      </div>
    </>
  );
}

export default Project;
