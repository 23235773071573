import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Tabs } from "antd";

import ProjectCard from "components/cards";
import { Separator, TwoSidedBlock, } from "components/common";

const bigText = {
  fontSize: "1.5em",
  textAlign: "center",
};

const Section = ({ title, children }) => {
  return (
    <>
      <h2>{title}</h2>
      <Separator height={0.5} />
      <Row gutter={16} className="vertical-center">
        {children}
      </Row>
    </>
  );
};

const ShortmoviesTab = ({ }) => {
  return (
    <Row gutter={[24, 24]} style={{padding:"24px"}}>
      <ProjectCard
        img={{
          alt: "Horny Shortmovie",
          src: "/img/thumbnails/horny.jpg",
        }}
        title="Horny - A Weird Story of Success and Beauty"
        title_logo="/img/logos/horny_logo_rect.png"
        description="Every legend has a beginning. Uncover Horny's dark past before he became Weird Frames' face."
        yt="https://youtu.be/5vn53NC-zDI"
        link="/projects/horny"
        ig="https://www.instagram.com/tv/CT7cGWtIK2Y"
        background_align="right"
      />
      
      <ProjectCard
        img={{
          alt: "Project Antares",
          src: "/img/thumbnails/antares.png",
        }}
        title="Project Antares"
        title_logo="/img/logos/antares_logo.png"
        description="We didn’t leave for a distant planet, we took our time to shape a new world right here on Earth."
        //yt="https://youtu.be/5vn53NC-zDI"
        link="/projects/antares"
        ig="https://www.instagram.com/p/CkJB9F5o1tZ"
        //background_align="right"
      />
    </Row>
  );
};

const VideogamesTab = ({ }) => {
  return (
    <div style={{padding:"24px"}}>
      <ProjectCard
        img={{
          alt: "Project Aurvandill",
          src: "/img/thumbnails/niflheim.jpg",
        }}
        title="Project Aurvandill"
        description="Beyond the sea, a distant land awaits those brave enough to face its mysteries and dangers. Join the mighty warrior Bodvar in his quest."
        //link="/projects/niflheim"
      />
    </div>
  );
};

const TechnologyTab = ({ }) => {
  return (
    <div style={{padding:"24px"}}>
      <ProjectCard
        img={{
          alt: "DL toolbox",
          src: "/img/technology/dl_ue_onnx.png",
        }}
        title="Deep Learning toolbox for Unreal"
        description="A suite of tools to simplify the use of Deep Learning models inside of Unreal Engine."
        //link="/projects/niflheim"
      />
    </div>
  );
};

const ContestsTab = ({ }) => {
  return (
    <div style={{padding:"24px"}}>
      <ProjectCard
        img={{
          alt: "iClone Contest - War Cry",
          src: "/img/thumbnails/warcry.jpg",
        }}
        badge="METAHUMAN AWARD"
        title="2021 iClone Lip-Sync Animation Contest - War Cry"
        description="A lone warrior in a hostile land. Ancient melodies and arcane rites. - Our entry at the '2021 iClone Lip-Sync Animation Contest' awarded with a Special Award in the MetaHuman category."
        yt="https://youtu.be/mJ8gRxjFbWY"
        link="/projects/reallusion2021"
        ext="https://www.reallusion.com/iclone/event/2021-lip-sync-animation-contest/winner.html"
      />
    </div>
  );
};

function Projects() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="slide slide-first" style={bigText}>
        <div className="container" style={{ textAlign: "left" }}>
          <div>
            <Tabs defaultActiveKey="1" style={{ width: "100%" }} size={"large"}>

              <Tabs.TabPane tab="Shorts" key="1">
                <ShortmoviesTab />
              </Tabs.TabPane>

              <Tabs.TabPane tab="Others" key="4">
                <ContestsTab />
              </Tabs.TabPane>

            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

export default Projects;
