import React, { useEffect } from "react";
import { Row, Col, Space } from "antd";
import Separator from "components/common/Separator";

import logo from "assets/logo.svg";

const background = {
  background:
    'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/img/pictures/etna.jpg")',
  backgroundSize: "cover",
  backgroundPosition: "center",
};

function App() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="slide slide-first white-text" style={background}>
        <div className="centered">
          <Row>
            <Col
              xs={{ offset: 2, span: 20 }}
              sm={{ offset: 3, span: 18 }}
              lg={{ offset: 6, span: 12 }}
            >
              <img
                src={logo}
                alt="mascotte"
                style={{ width: "192px", margin: "20px" }}
              />
              <Separator height={1.5} />
              <h4>
                Weird Frames was born in Catania in 2013 fueled by the
                passion for good stories.
              </h4>
            </Col>
          </Row>
        </div>
      </div>
      <div className="jumbotron">
        <div className="jumbotron-content">
          <h1>Our Mission</h1>
          <Separator height={1.5} />
          <p style={{ hyphens: "none" }}>
            {/*Weird frames is indipendent team, born with the mission of creating stories that people can get lost in.*/}
            We are dedicated to craft emotional stories for audiovisual media,
            supported by the most advanced technologies.
          </p>
        </div>
      </div>
      <div className="slide">
        <div className="centered">
          <Space direction="vertical" size="large">
            <h1>The Founders</h1>
            <Row>
              <Col
                xs={{ offset: 3, span: 18 }}
                md={{ offset: 6, span: 12 }}
                lg={{ offset: 5, span: 6 }}
              >
                <img
                  src="/img/avatar_gvecchio.png"
                  alt="mascotte"
                  style={{ width: "212px", margin: "0 0 20px" }}
                />
                <h3>Giuseppe Vecchio</h3>
                <p>
                  Computer Scientist, deep-learning researcher and 3D geek.
                  Graduated in <i>Computer Engineering</i> in 2020 at University
                  of Catania. Worked for three years as a software developer and
                  engineer and is currently pursuing a Ph.D. in Deep Learning
                  and Artificial Intelligence with several active publications
                  in the fileds of graphics and robotics.
                </p>
              </Col>

              <Col
                xs={{ offset: 3, span: 18 }}
                md={{ offset: 6, span: 12 }}
                lg={{ offset: 2, span: 6 }}
              >
                <img
                  src="/img/avatar_gtomarchio.png"
                  alt="mascotte"
                  style={{ width: "212px", margin: "0 0 20px" }}
                />
                <h3>Giuliano Tomarchio</h3>
                <p>
                  Screenwriter, video editor and filmmaker. Graduated with
                  honors in <i>Media Production Studies and Performing Arts</i>{" "}
                  in 2021 at Sapienza University of Rome. Worked as intern in{" "}
                  <i>Mercato Internazionale Audiovisivo</i> (MIA Market) and
                  attended a two-year Screenwriting course at the{" "}
                  <i>Sentieri Selvaggi Film School</i>.
                </p>
              </Col>
            </Row>
          </Space>
          <Separator height={8} />
          <Space direction="vertical" size="large">
            <h1>The Mascotte</h1>
            <Row>
              <Col
                xs={{ offset: 3, span: 18 }}
                md={{ offset: 6, span: 12 }}
                lg={{ offset: 9, span: 6 }}
              >
                <img
                  src="/img/horny.png"
                  alt="mascotte"
                  style={{ width: "212px", margin: "0 0 20px" }}
                />
                <h3>Horny</h3>
                <p>
                  Horny was born in forgotten times in the bowels of an active
                  volcano. Graduated in a prestigious University in New York
                  where he studied Cinema History and the art of acting. Worked
                  as a model for many prestigious brands. Nowadays proud face of
                  Weird Frames.
                </p>
              </Col>
            </Row>
          </Space>
        </div>
      </div>
    </>
  );
}

export default App;
