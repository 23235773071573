import React from "react";
import { Link } from "react-router-dom";
import { Badge, Card, Col } from "antd";
import {
  LinkOutlined,
  YoutubeOutlined,
  InstagramOutlined,
  //FacebookOutlined,
} from "@ant-design/icons";

import { Separator, TwoSidedBlock, } from "components/common";

const { Meta } = Card;

const ConditionalButton = ({
  url,
  icon = <LinkOutlined />,
  isLink = false,
}) => {
  if (!isLink) return url ? <a href={url}>{icon}</a> : null;
  else return url ? <Link to={url}>{icon} READ MORE</Link> : null;
};

const ConditionalBadge = ({ badgeText, children }) => {
  return badgeText ? (
    <Badge.Ribbon text={badgeText} color="green" style={{ right: "-20px" }}>
      {children}
    </Badge.Ribbon>
  ) : (
    <>{children}</>
  );
};

const ProjectCard = ({ title, title_logo, description, img, link, yt, ig, ext, badge, background_align="center" }) => {
  const actionsBlock = [];

  if (link) actionsBlock.push(<ConditionalButton url={link} isLink={true} />);
  if (yt)
    actionsBlock.push(
      <ConditionalButton url={yt} icon={<YoutubeOutlined />} />
    );
  if (ig)
    actionsBlock.push(
      <ConditionalButton url={ig} icon={<InstagramOutlined />} />
    );
  if (ext)
    actionsBlock.push(<ConditionalButton url={ext} icon={<LinkOutlined />} />);

  const card_title = title_logo ?
    <div style={{ textAlign: "center" }}><img src={title_logo} alt={title} style={{ width: "75%", padding: "24px" }} /></div> :
    <h4 style={{ whiteSpace: "initial" }}>{title}</h4>;

  return (
    <Col sm={24}>
      <ConditionalBadge badgeText={badge}>
        <TwoSidedBlock
          imageSrc={img.src}
          //title={title}
          //description={description}
          alignment="right"
          use_gradient={true}
          full_background={true}
          background_align = {background_align}
          gutter={24}
          rate={0.4}
        >
          <Col style={{ minHeight: "60vh", height: "100%" }}>
            <Card
              actions={actionsBlock}
              className="news-card clear"
            >
              <Meta
                style={{ marginBottom: "auto", textAlign: "justify" }}
                title={card_title}
                description={<p>{description}</p>}
              />
            </Card>
          </Col>
        </TwoSidedBlock>
      </ConditionalBadge>
    </Col>
  );
};

export default ProjectCard;
